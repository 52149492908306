import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Outlet, useLocation, Link as RouterLink } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import useTracking from '../useTracking';
import HomePage from 'components/HomePage';
import StatusPage from 'components/StatusPage';
import ManagePage from 'components/ManagePage';
import SupportPage from 'components/SupportPage';
import TermsPage from 'components/TermsPage';
import PaymentPage from 'components/PaymentPage';
import NotFoundPage from 'components/NotFoundPage';
import 'assets/scss/App.scss';

const NO_FOOTER_LINKS_PATHS = ['/status/', '/manage/'];


function Copyright(props) {
  const location = useLocation();

  useEffect(() => {
    const anchor = location.hash && document.querySelector(`a[name=${location.hash.substring(1)}]`);
    const top = anchor ? anchor.offsetTop : 0;
    window.scrollTo(0, top);
  }, [location.pathname, location.hash]);

  const showFooterLinks = !NO_FOOTER_LINKS_PATHS.some((p) => (location.pathname.indexOf(p) === 0));
  return (
    <>
      <Typography variant="caption" display="block" color="text.secondary" align="center" {...props}>
        {`Copyright © `}{new Date().getFullYear()} Lumination Labs, Inc.<br/>
        {showFooterLinks ? (
          <>
            <Link component={RouterLink} to="/" style={{ textDecoration: 'none', color: '#888' }}>Home</Link>{' | '}
            <Link component={RouterLink} to="/terms" style={{ textDecoration: 'none', color: '#888' }}>Terms and Conditions</Link>{' | '}
            <Link component={RouterLink} to="/terms#privacy" style={{ textDecoration: 'none', color: '#888' }}>Privacy Policy</Link>{' | '}
            <Link component={RouterLink} to="/support" style={{ textDecoration: 'none', color: '#888' }}>Support</Link>
          </>
        ) : (
          <>
            Powered by <a href="/" style={{ textDecoration: 'none', color: '#888' }}>Team Reminders</a>
          </>
        )}
      </Typography>
    </>
  );
}

const theme = createTheme({
  palette: {
    mode: 'light',
    // mode: 'dark',
    primary: {
      main: '#3a7db3',
    },
    secondary: {
      main: '#d8b629',
    },
  },
});

const App = () => {
  useTracking(GA_MEASUREMENT_ID);
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Paper>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
        </Routes>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              paddingTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Routes>
              <Route exact path="/" element={<Outlet/>} />
              <Route path="/status/:statusToken" element={<StatusPage />} />
              <Route path="/manage/:manageToken" element={<ManagePage />} />
              <Route path="/payment/:userRefId" element={<PaymentPage />} />
              <Route path="/support" element={<SupportPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </Box>
          <Copyright sx={{ mt: 8, pb: 2 }} />
        </Container>
      </Paper>
    </ThemeProvider>
  );
};

export default function AppContainer() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};
