import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

export default function TermsPage() {
  return (
    <Box align="left" sx={{ fontSize: '80%' }}>
      <Link component={RouterLink} to="/">Home</Link>
      <h1>Terms and Conditions</h1>
      <p>These terms and conditions govern your use of this website and associated products, services, add-ons, etc., hereafter referred to as "website"; by using this website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>
      <p>You must be at least 18 years of age to use this website. By using this website and by agreeing to these terms and conditions, you warrant and represent that you are at least 18 years of age.</p>
      <p>This website uses cookies. By using this website and agreeing to these terms and conditions, you consent to Lumination Labs's use of cookies in accordance with the terms of Lumination Labs's privacy policy.</p>
      <h5>License to use website</h5>
      <p>Unless otherwise stated, Lumination Labs and/or its licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</p>
      <p>You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.</p>
      <p>You must not:</p>
      <ul>
      <li>republish material from this website (including republication on another website);</li>
      <li>sell, rent or sub-license material from the website;</li>
      <li>show any material from the website in public;</li>
      <li>reproduce, duplicate, copy or otherwise exploit material on this website for a commercial purpose;</li>
      <li>edit or otherwise modify any material on the website; or</li>
      <li>redistribute material from this website</li>
      </ul>
      <h5>Acceptable use</h5>
      <p>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</p>
      <p>You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</p>
      <p>You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without Lumination Labs's express written consent.</p>
      <p>You must not use this website to transmit or send unsolicited commercial communications.</p>
      <p>You must not use this website for any purposes related to marketing without Lumination Labs's express written consent.</p>
      <h5>Restricted access</h5>
      <p>Access to certain areas of this website is restricted. Lumination Labs reserves the right to restrict access to areas of this website, or indeed this entire website, at Lumination Labs's discretion.</p>
      <p>If Lumination Labs provides you with a user ID and password (or credentials associated with a third-party identity provider) to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password (or those credentials associated with the associated third-party identity provider) are kept confidential.</p>
      <p>Lumination Labs may disable your user account and associated access tokens or credentials at Lumination Labs's sole discretion without notice or explanation.</p>
      <h5>User content</h5>
      <p>In these terms and conditions, "your user content" means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website (directly or via authorization to access material hosted by a third party), for whatever purpose.</p>
      <p>Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or Lumination Labs or a third party (in each case under any applicable law).</p>
      <p>You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</p>
      <p>Lumination Labs reserves the right to edit or remove any material submitted to this website, or stored on Lumination Labs's servers, or hosted or published upon this website.</p>
      <p>Notwithstanding Lumination Labs's rights under these terms and conditions in relation to user content, Lumination Labs does not undertake to monitor the submission of such content to, or the publication of such content on, this website.</p>
      <h5>Price and Payment</h5>
      <p>The price of goods or services is stated on the website and will be charged to your chosen form of payment within thirty (30) days of order processing.</p>
      <p>In connection to purchasing goods or services from Lumination Labs (directly or indirectly through the use of connected third-party services), you agree to pay all unpaid amounts invoiced to your account, in full, within thirty (30) days of order processing.</p>
      <p>In the event that Lumination Labs is unable to automatically charge the invoiced amount using the information you have provided, you agree to provide details for a suitable replacement form of payment in order to pay your remaining unpaid invoices.</p>
      <p>Failure to pay or provide the information necessary to process payment for outstanding invoices in a timely manner may affect your ability to use the services and/or order goods from Lumination Labs. Your account and any outstanding orders may be suspended or terminated by Lumination Labs at any time without prior notice or explanation.</p>
      <p>Should any dispute arise with regard to invoices or payments, both parties will seek to resolve the dispute expeditiously and in good faith.</p>
      <h5>Cancellation</h5>
      <p>You may request cancellation of your account at any time by contacting Lumination Labs support and requesting account cancellation. If any invoices in your account are still outstanding, you will be required to pay them in full at the time of cancellation unless both parties determine that an error has been made and/or both parties agree that an exception should be made. Account cancellation requests will be processed within thirty (30) business days from the time the request is received and all unpaid amounts are settled, whichever comes later.</p>
      <h5>No warranties</h5>
      <p>This website is provided "as is" without any representations or warranties, express or implied. Lumination Labs makes no representations or warranties in relation to this website or the information and materials provided on this website.</p>
      <p>Without prejudice to the generality of the foregoing paragraph, Lumination Labs does not warrant that:</p>
      <ul>
      <li>this website will be constantly available, or available at all; or</li>
      <li>the information on this website is complete, true, accurate or non-misleading.</li>
      </ul>
      <p>Nothing on this website constitutes, or is meant to constitute, advice of any kind.</p>
      <h5>Limitations of liability</h5>
      <p>Lumination Labs will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:</p>
      <ul>
      <li>for any indirect, special or consequential loss; or</li>
      <li>for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.</li>
      </ul>
      <p>These limitations of liability apply even if Lumination Labs has been expressly advised of the potential loss.</p>
      <h5>Exceptions</h5>
      <p>Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to exclude or limit; and nothing in this website disclaimer will exclude or limit Lumination Labs's liability in respect of any:</p>
      <ul>
      <li>death or personal injury caused by Lumination Labs's negligence;</li>
      <li>fraud or fraudulent misrepresentation on the part of Lumination Labs; or</li>
      <li>matter which it would be illegal or unlawful for Lumination Labs to exclude or limit, or to attempt or purport to exclude or limit, its liability.</li>
      </ul>
      <h5>Reasonableness</h5>
      <p>By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable.</p>
      <p>If you do not think they are reasonable, you must not use this website.</p>
      <h5>Other parties</h5>
      <p>You accept that, as a limited liability entity, Lumination Labs has an interest in limiting the personal liability of its officers and employees.  You agree that you will not bring any claim personally against Lumination Labs's officers or employees in respect of any losses you suffer in connection with the website.</p>
      <p>You agree that the limitations of warranties and liability set out in this website disclaimer will protect Lumination Labs's officers, employees, agents, subsidiaries, successors, assigns and sub-contractors as well as Lumination Labs.</p>
      <h5>Unenforceable provisions</h5>
      <p>If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.</p>
      <h5>Indemnity</h5>
      <p>You hereby indemnify Lumination Labs and undertake to keep Lumination Labs indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Lumination Labs to a third party in settlement of a claim or dispute on the advice of Lumination Labs's legal advisers) incurred or suffered by Lumination Labs arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.</p>
      <h5>Breaches of these terms and conditions</h5>
      <p>Without prejudice to Lumination Labs's other rights under these terms and conditions, if you breach these terms and conditions in any way, Lumination Labs may take such action as Lumination Labs deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.</p>
      <h5>Variation</h5>
      <p>Lumination Labs may revise these terms and conditions from time-to-time.  Revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website.  Please check this page regularly to ensure you are familiar with the current version.</p>
      <h5>Assignment</h5>
      <p>Lumination Labs may transfer, sub-contract or otherwise deal with Lumination Labs rights and/or obligations under these terms and conditions without notifying you or obtaining your consent.</p>
      <p>You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</p>
      <h5>Severability</h5>
      <p>If a provision of these terms and conditions is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions will continue in effect.  If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect.</p>
      <h5>Entire agreement</h5>
      <p>These terms and conditions, together with the Privacy Policy, constitute the entire agreement between you and Lumination Labs in relation to your use of this website, and supersede all previous agreements in respect of your use of this website.</p>
      <h5>Law and jurisdiction</h5>
      <p>These terms and conditions will be governed by and construed in accordance with the laws of the State of Utah, and any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of Utah, USA.</p>
      <br/>
      <a name='privacy'></a>
      <h2>Privacy Policy</h2>
      <p>Your privacy is important to Lumination Labs. This privacy statement provides information about the personal information that Lumination Labs collects, and the ways in which Lumination Labs uses that personal information.</p>
      <h5>Personal information collection</h5>
      <p>Lumination Labs may collect and use the following kinds of personal information:</p>
      <ul>
      <li>information about your use of this website (including your computer's IP address, browser information, dates and times of access, etc);</li>
      <li>information that you provide using for the purpose of registering with the website (including access tokens, usernames, passwords, address, payment details, etc);</li>
      <li>information about transactions carried out over this website (including payment transactions, communications, etc);</li>
      <li>information that you provide (or is gathered with permission from third-party services) for the purpose of using the website services;</li>
      <li>information obtained through Google APIs with your consent; and</li>
      <li>any other information that you send to Lumination Labs.</li>
      </ul>
      <h5>Using personal information</h5>
      <p>Lumination Labs may use your personal information to:</p>
      <ul>
      <li>administer this website;</li>
      <li>personalize the website for you;</li>
      <li>enable your access to and use of the website services;</li>
      <li>supply to you services that you purchase;</li>
      <li>communicate with you; and</li>
      <li>collect payments from you.</li>
      </ul>
      <p>Where Lumination Labs discloses your personal information (excluding data obtained from Google APIs) to its agents or sub-contractors for these purposes, the agent or sub-contractor in question will be obligated to use that personal information in accordance with the terms of this privacy statement. Lumination Labs's use of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.</p>
      <p>In addition to the disclosures reasonably necessary for the purposes identified elsewhere above, Lumination Labs may disclose your personal information to the extent that it is required to do so by law, in connection with any legal proceedings or prospective legal proceedings, and in order to establish, exercise or defend its legal rights.</p>
      <h5>Securing your data</h5>
      <p>Lumination Labs will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>
      <p>Lumination Labs will store all the personal information you provide on its secure servers.</p>
      <p>Information relating to electronic transactions entered into via this website will be protected by encryption technology.</p>
      <h5>Cross-border data transfers</h5>
      <p>Information that Lumination Labs collects may be stored and processed in and transferred between any of the countries in which Lumination Labs operates to enable the use of the information in accordance with this privacy policy.</p>
      <p>You agree to such cross-border transfers of personal information.</p>
      <h5>Updating this statement</h5>
      <p>Lumination Labs may update this privacy policy by posting a new version on this website.</p>
      <p>You should check this page occasionally to ensure you are familiar with any changes.</p>
      <h5>Other websites</h5>
      <p>This website contains links to other websites.</p>
      <p>Lumination Labs is not responsible for the privacy policies or practices of any third party.</p>
      <p><br/>Date Last Updated: February 12, 2022</p>
    </Box>
  )
}
