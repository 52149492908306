import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import ScreenShot from '../assets/img/screenshot.png';
import Settings from '../assets/img/settings.png';
import { ADDON_URL } from './HomePage';

export default function SupportPage() {
  return (
    <Box align="left">
      <Link component={RouterLink} to="/">Home</Link>
      <h2>Team Reminders Help Guide</h2>
      <h4>Contents</h4>
      <ul>
        <li><Link href="#how_it_works">How It Works</Link>
          <ul>
            <li><Link href="#overview">Overview</Link></li>
            <li><Link href="#getting_started">Getting Started</Link></li>
            <li><Link href="#first_reminder">Sending Your First Reminder</Link></li>
            <li><Link href="#example_message">Example Message</Link></li>
          </ul>
        </li>
        <li><Link href="#automatic_reminders">Automatic Reminders</Link></li>
        <li><Link href="#rescheduled">Rescheduled and Deleted Events</Link></li>
        <li><Link href="#delivery_considerations">Delivery Considerations</Link></li>
        <li><Link href="#multiple_reminders">Multiple Reminders</Link></li>
        <li><Link href="#wrapping_up">Wrapping Up</Link></li>
      </ul>
      <br/>
      <h3><a name="how_it_works" />How It Works</h3>
      <p><a name="overview" /><b>Overview</b><br/>
        The <b>Team Reminders</b> add-on for Google Calendar enables you to create a list of attendees for a calendar so you can remind them of upcoming events using text messaging (SMS).
      </p>
      <p>
        It's important to understand that a <u>single list of attendees</u> is shared for all events in the calendar. This makes it easy to remind the same group of people (such as a team or club) about events on an ongoing basis. Of course, you can add and remove people from the list at any time, but those changes apply to all events in the calendar (not just one event).
      </p>
      <Box component="img" src={ScreenShot} sx={{ maxWidth: '100%', mt: 2, mb: 2, border: '1px solid' }} />
      <p><a name="getting_started" /><b>Getting Started</b><br/>
        If you haven't already installed the add-on, <a href={ADDON_URL} target="_blank" rel="noopener noreferrer">click here</a> and install it using your Google account. Once it's installed, open Google Calendar in a web browser and click the <b>Team Reminders</b> icon in the panel on the right-hand side of your calendar (you may need to click a small arrow to expand the panel).
      </p>
      <p>
        The first time you open the add-on, you'll need to click "<b>Authorize Access</b>" to give the add-on access to your calendar. The add-on requires <u>read-only</u> access, so it won't be able to change or delete any of your calendar data.
      </p>
      <p>
        NOTE: If nothing happens when you click "Authorize Access", you may need to check to see if your browser has blocked the pop-up authorization window. Most browsers have an option to allow pop-up windows for the current website.
      </p>
      <p>
        Finally, you need to decide which calendar (or calendars) to use with the add-on. Since Google Calendar supports multiple calendars in the same calendar view, you can enable or disable calendars to be used with the add-on at any time. We recommend <a href="https://support.google.com/calendar/answer/37095?hl=en" target="_blank" rel="noopener noreferrer">creating a dedicated calendar</a> for your team or group if you haven't already done so. This helps keep your group's events organized and separated from any other personal events you may have. When you've decided on a calendar to use, click on an event for that calendar (or create a new event), and then click "<b>Enable For This Calendar</b>" in the add-on panel.
      </p>
      <p><a name="first_reminder" /><b>Sending Your First Reminder</b><br/>
        The first thing you need to do is to set up your attendees list. You can do this by clicking the "<b>Settings</b>" button, followed by "<b>Add Attendee</b>". If you'd like, you can add your own name and phone number as a test, or start adding members of your group right away. You can return to "Settings" at any time to update the list. To remove someone, just click the "x" to the right of their name in "Settings".
      </p>
      <p>
        Once you have at least one attendee in "Settings", go ahead and click "Done" to return to the main screen. At this point, your event title should be listed at the top of the panel, with a "<b>Send Reminder...</b>" button below. If the button is disabled (and the "<b>Messages Left</b>" indicator below shows <b>0</b>), you'll need to add message credits to your account. These can be added using the "<b>Purchase</b>" button and choosing one of the "Buy Now" options. The "Buy Now" button opens a pop-up window, so you may need to check your browser if nothing happens (as with the "Authorize Access" button mentioned earlier).
      </p>
      <p>
        When you've completed a payment, you'll notice the "Messages Left" indicator increase by the number of messages you purchased. If it doesn't, double-check that your payment was successful or try again. Your balance of "Messages Used" and "Messages Left" is shared for all enabled calendars within your Google account.
      </p>
      <p>
        If you have at least one message credit, you can send your first reminder. The "Messages Left" number will be red if you have fewer remaining messages than the number of attendees in your list; in that case, you can still send a reminder, but some attendees may not receive it if you run out of messages.
      </p>
      <p>
        Great &mdash; to send the reminder, just click "Send Reminder...", review the guidelines, then "<b>Send Reminder Now</b>". Within a few moments, your attendees should receive the reminder text message for the selected event.
      </p>
      <p><a name="example_message" /><b>Example Message</b></p>
      <Alert severity="success" icon={false}>
        Reminder for Katie:<br/>
        Group Social Event @ The Clubhouse<br/>
        {['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'][(new Date().getMonth() + 1) % 12]} 18, {new Date().getFullYear() + (new Date().getMonth() == 11 ? 1 : 0)} at 6:00 pm<br/>
        Planning to attend? Reply YES, NO, MAYBE, or STOP.
      </Alert>
      <p>
        Congratulations! You can now view your attendees' RSVP statuses in the add-on under "<b>Event RSVPs</b>". The RSVP statuses are <b>event-specific</b>, so clicking on a different event will show them for that event.
      </p>
      <h3><a name="automatic_reminders" />Automatic Reminders</h3>
      <Box component="img" src={Settings} sx={{ maxWidth: '30%', border: '1px solid', float: 'right', display: { xs: 'none', sm: 'block' } }} />
      <p>
        So far you've used the "Send Reminder..." button to manually send a reminder, but what if you want to automatically send reminders as each event in your calendar approaches? To do that, head to "Settings" and enable the "<b>Send reminders automatically</b>" option. The first time you turn it on, you'll need to authorize offline access so we can process events in the background (even when you don't have your calendar open).
      </p>
      <p>
        With automatic reminders enabled, you can choose an appropriate "<b>Time before events to send reminders</b>" and click "Done". To know when the next reminder will be sent, look at the add-on under "<b>Next Automatic Reminder For Calendar</b>". If the currently selected event is scheduled for automatic delivery, the estimated delivery time will be listed below the "Send Reminder..." button. You may need to click the "<b>Refresh</b>" button in the add-on to reflect the latest delivery estimate, as well as the latest RSVP statuses.
      </p>
      <Box component="img" src={Settings} sx={{ maxWidth: '75%', border: '1px solid', display: { xs: 'block', sm: 'none' }, marginLeft: 'auto', marginRight: 'auto' }} />
      <h3><a name="rescheduled" />Rescheduled and Deleted Events</h3>
      <p>
        If a reminder has already been sent for an event and the event is later rescheduled, you can send a new reminder message indicating the new date and time of the event by clicking on the "Resend Reminder..." button (where the "Send Reminder..." normally is). Similar to the original reminder message, the new reminder will include the new date and time, along with the option for attendees to revise their RSVP status.
      </p>
      <p>
        If automatic reminders are enabled, you will (by default) receive an email notice within five minutes of rescheduling or deleting an event that has had reminders sent. The email notice will contain a link allowing you to send a new reminder (or notice of cancellation) at your convenience. To always send rescheduling and cancellation notices to attendees without asking you first by email, you can change the "<b>Notify about canceled or rescheduled events</b>" option in "Settings".
      </p>
      <h3><a name="delivery_considerations" />Delivery Considerations</h3>
      <p>
        <b>Team Reminders</b> does its best to send reminders that are well-received by attendees. As a result, sometimes the timing can differ from what you might expect. Here are some of the factors that influence delivery timing:
      </p>
      <ul>
        <li>If you recently turned on automatic reminders, no events will be scheduled for automatic delivery if they start too close to the moment you turned on the setting (specifically, within the time set for the "Time before events to send reminders" setting).</li>
        <li>Automatic reminders are normally delivered only between 8am and 9pm in your calendar's time zone. If the setting for "Time before events to send reminders" causes the scheduled reminder to fall outside those hours, it will be delivered <b>earlier</b> to be safe (e.g., for an event starting Wednesday at 6:30am and set to remind one day before, the reminder will be sent Monday evening). Manually-sent reminders can be sent at any time of the day, but we advise you to stay within daytime hours whenever possible.</li>
        <li>A newly-scheduled event may be skipped for automatic reminders if its start time is closer than half of the "Time before events to send reminders" setting. In this case it's considered too late for automatic delivery, but you can still send a manual reminder if you'd like.</li>
        <li>If you've already sent a reminder for an event, you cannot manually send another reminder for the same event until at least six hours have passed (except when rescheduled). Similarly, you cannot send more than three reminders for events in the same calendar within a six-hour window.</li>
        <li>When "Notify about canceled or rescheduled events" is set to "Always notify attendees", those notifications are normally delivered: (a) within five minutes of the event change at the soonest, if between 8am and 9pm; (b) otherwise at 8am; except when (c) the new event time (or original time of a cancelled event) starts within 3 hours, in which case the notification may be sent during the night.</li>
      </ul>
      <h3><a name="multiple_reminders" />Multiple Reminders</h3>
      <p>
        As mentioned, <b>Team Reminders</b> limits the number of reminders you can send in a short time. Since attendees receive reminders as SMS messages (from the same phone number whenever possible), it can be confusing &mdash; and somewhat annoying &mdash; to receive multiple reminders in succession. If a reminder is sent before the attendee has had a chance to respond to a previous one (sent in the past two weeks and for an event that hasn't occurred), the new reminder will include response options with an appended number (e.g., "YES2, NO2, or MAYBE2"). This allows the attendee to respond/RSVP to a specific reminder, even if it's not the most recent one received. While this works fine for one or two reminders, it can easily become overwhelming, so you should keep successive reminders to a minimum.
      </p>
      <h3><a name="wrapping_up" />Wrapping Up</h3>
      <p>
        Hopefully you find <b>Team Reminders</b> to be as useful as it's been for us. We'd love to hear how you're using it and welcome your constructive feedback.
      </p>
      <p>
        Thanks!
      </p>
      <br/>
      <br/>
      <h3 align="center">Other Questions?</h3>
      <p align="center">Email Support: <b>support@itextyou.com</b></p>
    </Box>
  )
}
