import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function useTracking(trackingId) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!window.gtag || !trackingId) return;
    window.gtag('config', trackingId, { page_path: pathname });
  }, [trackingId, pathname]);
};
