import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';

import { createApi, formatCurrency } from '../util';

const api = createApi();


export default function PaymentPage() {
  const { userRefId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);

  const fetchPaymentOptions = async () => {
    try {
      const response = await api.get(`/payment_options/${userRefId}`);
      if (response?.data?.packages) {
        setPaymentOptions(response.data.packages);
        setIsLoading(false);
      } else {
        throw new Error();
      }
    } catch (e) {
      setError('Invalid payment link');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentOptions();
  }, []);


  const onClick = () => {};

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Typography component="h1" variant="h6" sx={{ mt: 1, mb: 2, fontWeight: 'bold' }} align="center">
        Team Reminders - Add More Messages
      </Typography>
      <Box sx={{ maxWidth: 300 }}>
        {paymentOptions.map((paymentOption) => (
          <Button
            key={paymentOption.credits}
            href={paymentOption.link}
            fullWidth
            variant="contained"
            sx={{ mt: 1 }}
          >
            {`${paymentOption.credits} Messages: ${formatCurrency(paymentOption.price)}`}
          </Button>
        ))}
      </Box>
    </>
  )
}
