import axios from 'axios';

export const createApi = () => {
  return axios.create({
    baseURL: API_BASE,
    timeout: 30000,
  });
};

export const formatCurrency = (input) => (
  `$${(Math.round(input * 100) / 100).toFixed(2)}`
);
