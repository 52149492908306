import React, { useState, useEffect } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';

import { createApi } from '../util';

const STATUS_LABELS = {
  accepted: 'Confirmed',
  declined: 'Not Attending',
  tentative: 'Maybe Attending',
};
const FALLBACK_STATUS = 'Unknown Status';
const TEST_TOKEN = 'test';
const TEST_STATUS = {
  data: {
    title: 'Test Event',
    attendees: [
      { name: 'Attendee 1', status: 'accepted' },
      { name: 'Attendee 2', status: 'tentative' },
      { name: 'Attendee 3', status: 'declined' },
      { name: 'Attendee 4', status: 'unknown' },
    ],
    my_status: 'accepted'
  }
};

const api = createApi();

const iconForStatus = (status) => {
  switch (status) {
    case 'accepted':
      return <CheckIcon color="success" />;
    case 'declined':
      return <DoNotDisturbAltIcon color="warning" />;
    case 'tentative':
      return <DoneOutlineIcon color="disabled" />;
  }
  return <RadioButtonUncheckedIcon />;
};


export default function StatusPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(null);
  const [attendees, setAttendees] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(null);
  const { statusToken } = useParams();

  
  const fetchStatuses = async () => {
    try {
      const response = statusToken === TEST_TOKEN ? TEST_STATUS : await api.get(`/event_statuses/${statusToken}`);
      setTitle(response.data.title);
      setAttendees(response.data.attendees || []);
      setCurrentStatus(response.data.my_status);
      setButtonStatus(response.data.my_status);
      setIsLoading(false);
    } catch (e) {
      setError('Event passed or not found.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStatuses();
  }, []);

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  useEffect(() => {
    if (buttonStatus !== currentStatus) {
      (async() => {
        try {
          const response = await api.post(`/event_statuses/${statusToken}/status`, { status: buttonStatus });
          if (response.data?.success) {
            fetchStatuses();
          }
        } catch (e) {
          setButtonStatus(currentStatus);
        }
      })();
    }
  }, [buttonStatus]);

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 'xs' }}>
      <Typography component="h1" variant="h5" sx={{ mt: 0 }} align="center">
        {title || '(Untitled Event)'}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 3, mb: 3 }}>
        <Typography sx={{ fontWeight: 'bold', mr: 1 }}>My Response:</Typography>
        <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          <Button disabled={buttonStatus !== currentStatus} variant={buttonStatus === 'accepted' ? 'contained' : undefined} sx={buttonStatus === 'accepted' ? { backgroundColor: 'info.main', color: 'white' } : { color: 'info.main' }} onClick={() => setButtonStatus('accepted')}>Yes</Button>
          <Button disabled={buttonStatus !== currentStatus} variant={buttonStatus === 'tentative' ? 'contained' : undefined} sx={buttonStatus === 'tentative' ? { backgroundColor: 'info.main', color: 'white' } : { color: 'info.main' }} onClick={() => setButtonStatus('tentative')}>Maybe</Button>
          <Button disabled={buttonStatus !== currentStatus} variant={buttonStatus === 'declined' ? 'contained' : undefined} sx={buttonStatus === 'declined' ? { backgroundColor: 'info.main', color: 'white' } : { color: 'info.main' }} onClick={() => setButtonStatus('declined')}>No</Button>
        </ButtonGroup>
      </Box>
      {(attendees.length > 0) && (
        <>
          <Typography component="h1" variant="h5" sx={{ mt: 2, mb: 1, fontWeight: 'bold' }} align="center">
            Attendees
          </Typography>
          <List dense>
            {attendees.map((attendee, i) => (
              <ListItem key={`${i}-${attendee.name}`} divider disablePadding>
                <ListItemIcon>{iconForStatus(attendee.status)}</ListItemIcon>
                <ListItemText
                  primary={attendee.name}
                  primaryTypographyProps={{ fontWeight: 'bold' }}
                  secondary={STATUS_LABELS[attendee.status] || FALLBACK_STATUS}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </Box>
  )
}
