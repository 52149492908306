import React, { useState, useEffect } from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';

import { createApi } from '../util';


const api = createApi();


export default function ManagePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [title, setTitle] = useState(null);
  const [dateLine, setDateLine] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [canSend, setCanSend] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const { manageToken } = useParams();

  
  const fetchEventInfo = async () => {
    try {
      const response = await api.get(`/manage_event/${manageToken}`);
      setTitle(response.data.title);
      setDateLine(response.data.local_formatted_time);
      setIsDeleted(response.data.is_deleted);
      setCanSend(response.data.can_send);
      setIsLoading(false);
    } catch (e) {
      setError('Event not found or this link has already been used.');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEventInfo();
  }, []);

  const sendReminders = async () => {
    try {
      setIsLoading(true);
      const response = await api.post(`/manage_event/${manageToken}/send_reminder`);
      if (response.data?.success) {
        setIsDone(true);
        setIsLoading(false);
      } else {
        throw new Error();
      }
    } catch (e) {
      setError('Unable to send at this time.');
      setIsLoading(false);
    }
  };

  if (error) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    )
  }

  if (isDone) {
    return (
      <Box sx={{ display: 'flex' }}>
        <Alert severity="success">Success! Messages will be sent as soon as possible.</Alert>
      </Box>
    )
  }

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 'xs' }}>
      <Typography component="h1" variant="h5" sx={{ mt: 0 }} align="center">
        {title || '(Untitled Event)'}
      </Typography>
      <Typography component="p" sx={{ mt: 1, textDecoration: (isDeleted ? 'line-through' : 'none') }} align="center">
        {dateLine}
      </Typography>
      <Typography sx={{ mt: 4 }}  align="center">
        <Button disabled={!canSend} variant="contained" onClick={sendReminders}>{isDeleted ? 'Notify of Cancellation' : 'Send Updated Event Reminder'}</Button>
      </Typography>
      {canSend ? (
        <Typography component="p" sx={{ fontSize: '80%', mt: 4 }} align="center">
          Note: Messages will be sent immediately.<br/><b>Please avoid sending at late hours unless absolutely necessary.</b>
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Alert severity="error">Too many reminders have been sent for your calendar recently.<br/>Please try again later.</Alert>
        </Box>
      )}
    </Box>
  )
}
