import React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';

import logoImg from '../assets/img/logo.png';
import howitworksImg from '../assets/img/howitworks.png';

export const ADDON_URL = 'https://workspace.google.com/marketplace/app/team_reminders/346740991603';


export default function HomePage() {

  return (
    <>
      <Container component="main" maxWidth="sm" sx={{ pb: 4 }}>
        <Box
          sx={{
            paddingTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container>
            <Grid item sm={2} xs={12} align="center">
              <Box component="img" src={logoImg} sx={{ mt: 2, maxWidth: 100 }} />
            </Grid>
            <Grid item sm={10} xs={12}>
              <Typography component="h1" variant="h3" sx={{ mt: 3, fontWeight: 'light' }} align="center">
                Team Reminders
              </Typography>
              <Typography component="h1" variant="h5" align="center" sx={{ color: '#777' }}>
                SMS Reminders and RSVPs
              </Typography>
              <Typography component="h1" variant="h5" align="center" sx={{ color: '#777' }}>
                For Teams, Clubs, & Other Groups
              </Typography>
            </Grid>
          </Grid>
          <Typography component="h1" variant="h6" sx={{ mt: 3, fontWeight: 'bold' }} align="center">
            Google Calendar Add-On
          </Typography>
          <Box sx={{ mt: 0 }}>
            <Button
              href={ADDON_URL}
              target="_blank"
              rel="noopener noreferrer"
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2, backgroundColor: '#2b61aa' }}
            >
              Install Add-On
            </Button>
          </Box>
          <Typography component="h1" variant="h5" sx={{ mt: 5, fontWeight: 'light' }} align="center">
            Features:
          </Typography>
          <Box sx={{ mt: 1 }}>
            <ul>
              <li>Automatically text reminders to event attendees via SMS</li>
              <li>Allow attendees to RSVP with Yes, No, or Maybe</li>
              <li>Use a common list of attendees for all events</li>
              <li>See a list of attendees' statuses for each event</li>
              <li>Keep attendees updated if an event is rescheduled or canceled</li>
              <li>Best of all: <b>attendees <u>do not need to install anything</u> or create an account to use it!</b></li>
            </ul>
          </Box>

        </Box>
      </Container>

      <Box sx={{ 
        backgroundColor: '#603d78',
        backgroundImage: 'linear-gradient(to bottom left, #2b61aa, #603d78, #392663)',
        pt: 2, 
        pb: 8,
      }}>
        <Container component="main" maxWidth="sm">
          <Box
            sx={{
              paddingTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textShadow: 'black 0 0 4px',
            }}
          >
            <Typography component="div" variant="body1" sx={{ mt: 2, fontWeight: 'light', color: '#eee' }} align="center">
              TEAM REMINDERS IS PERFECT FOR<br/><b>sports teams</b> • <b>youth groups</b> • <b>clubs</b> • <b>seminars</b> • <b>recurring activities</b>
            </Typography>
            <Box component="img" src={howitworksImg} sx={{ mt: 5, mb: 2, maxWidth: '80%', backgroundColor: '#fff', p: 2, borderRadius: 3 }} />
            <Typography component="h1" variant="h5" sx={{ mt: 3, fontWeight: 'light', color: '#eee'  }} align="center">
              How It Works:
            </Typography>
            <Box sx={{ mt: 1, color: '#eee'  }}>
              <ol>
                <li>Add contacts to your group's Google Calendar by entering a list of names and SMS/phone numbers.</li>
                <li>Set how long before each event you want your reminders to be sent, and turn on <b>automatic reminders</b>. You can also send reminders manually.</li>
                <li>Each attendee receives a <b>text message reminder</b> before each event.</li>
                <li>Attendees can respond directly to the text message to indicate their RSVP status. <b>No need to create an account or install an app</b>!</li>
                <li>Check a live RSVP status list for everyone in the group.</li>
              </ol>
              <Typography align="center" sx={{ color: '#eee' }}>
                For details, check out the <Link component={RouterLink} to="/support" style={{ color: '#cef', fontWeight: 'bold' }}>Team Reminders Help Guide</Link>.
              </Typography>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Button
                href={ADDON_URL}
                target="_blank"
                rel="noopener noreferrer"
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 2, color: '#093563', backgroundColor: '#fd0', textShadow: 'none' }}
              >
                Install Add-On
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            paddingTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: 4 }}>
            <Typography component="p" sx={{ fontSize: '80%' }}>
              This add-on's use and transfer to any other app of information received from Google APIs will adhere to the Google API Services User Data Policy, including the Limited Use requirements.
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  )
}
