import React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <>
      <Link component={RouterLink} to="/">Home</Link>
      <br/>
      <Box sx={{ display: 'flex' }}>
        <Alert severity="error">404: Page Not Found</Alert>
      </Box>
    </>
  )
}
